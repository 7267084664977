import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { FiArrowRight } from 'react-icons/fi';
import { ImSpinner } from "react-icons/im";
import { formatCurrency } from '../utils/currencyUtils';
import { exportToCSV } from '../utils/exportCSV';
import CustomPagination from './CustomPagination/CustomPagination';
import SkeletonTable from './common/SkeletonTable';
//import sampleData from '../data/sample_data.json';

const customStyles = {
    headRow: {
      style: {
        backgroundColor: 'rgb(248, 250, 252)',
        fontWeight: "bold",
      },
    },
};

const TransactionTable = ({ transactions, loading }) => {
  const [filterText, setFilterText] = useState('');
  /*const [data, setData] = useState([]);
  
  useEffect(() => {
    // Load data from JSON file
    setData(sampleData);
  }, []);*/

  const columns = [
    {
      name: "Who Paid?",
      cell: row => {
        const parentName = row.metadata?.parent_name;
        return parentName && parentName !== "undefined undefined" ? parentName : 'N/A';
      },
    },
    {
      name: "Student Name",
      cell: row => row.child ? `${row.child.firstname} ${row.child.lastname}` : 'N/A',
    },
    {
      name: "Student Class",
      cell: row => row.metadata?.class?.class_name || 'N/A',
    },
    {
      name: "Amount Paid",
      cell: row => formatCurrency(row.amount),
    },
    {
      name: "Total Fees",
      cell: row => formatCurrency(row.total_amount),
    },
    {
      name: "Total Paid",
      cell: row => formatCurrency(row.amount_after),
    },
    {
      name: "Balance",
      cell: row => formatCurrency(Number(row.total_amount) - Number(row.amount_after)),
    },
    {
      name: "Paid At",
      cell: row => new Date(row.updatedAt).toLocaleString('en-NG', { hour12: true }),
      sortable: true,
    },
    {
      cell: (row) => (
        <Link to={`/transaction-details/${row._id}`}>
          <button style={{ color: '#1e4db7', backgroundColor: '#91a6f5' }} className="text-md opacity-0.9 rounded-full p-3 hover:drop-shadow-xl">
            <FiArrowRight />
          </button>
        </Link>
      ),
      ignoreRowClick: true,
      button: true,
    },
  ];  

  const filteredData = transactions?.filter((item) =>
    (item.child?.firstname?.toLowerCase().includes(filterText.toLowerCase()) || '') ||
    (item.child?.lastname?.toLowerCase().includes(filterText.toLowerCase()) || '') ||
    (item.metadata?.parent_name?.toLowerCase().includes(filterText.toLowerCase()) || '') ||
    (item.metadata?.class?.class_name?.toLowerCase().includes(filterText.toLowerCase()) || '')
  );
  
    return (
      <div className="rounded-2xl">
        <div className="flex justify-between items-center mb-4">
          <input
            type="text"
            className="px-4 py-2 rounded-lg bg-gray-100 border-gray-100 text-gray-900"
            placeholder="Search..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <button onClick={() => exportToCSV(filteredData, columns, `All-transactions.csv`)} className="inline-flex justify-center py-1 px-8 shadow-sm text-sm font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 float-right">
            Export to CSV
          </button>
        </div>
        <DataTable
          columns={columns}
          data={filteredData}
          getRowId={(row) => row._id}
          pagination
          paginationComponent={CustomPagination}
          paginationRowsPerPageOptions={[10, 20, 30, 50]}
          customStyles={customStyles}
          progressPending={loading}
          progressComponent={<SkeletonTable rows={5} columns={6} />}
          highlightOnHover
          striped
        />
      </div>
    );
};

export default TransactionTable;