import axios from "axios";
import Cookies from 'js-cookie';

const BASE_URL = "https://productionapi.acceede.com/" //Production AWS
//const BASE_URL = "https://acceedeapi.herokuapp.com" //Production
//const BASE_URL = "https://staging-s55s.onrender.com" //staging old
//const BASE_URL = "https://stagingapi.acceede.com" //staging AWS

export const publicRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    'X-Requested-With': 'XMLHttpRequest',
    "x-access-token": Cookies.get('accessToken') || '',
  },
});

let isRefreshing = false;
let failedRequestsQueue = [];

async function refreshAccessToken() {
  const tokenWithUser = JSON.parse(localStorage.getItem("tokenWithUser"));
  if (!tokenWithUser?.refresh_token) {
    throw new Error("No refresh token available");
  }
  
  try {
    const response = await publicRequest.post('/auth/refresh-token', {
      refresh_token: tokenWithUser.refresh_token
    });
    
    const newAccessToken = response.data.access_token; // Adjust according to API response
    Cookies.set('accessToken', newAccessToken);
    userRequest.defaults.headers.common['x-access-token'] = newAccessToken;
    return newAccessToken;
  } catch (error) {
    Cookies.remove('accessToken');
    localStorage.removeItem("tokenWithUser");
    window.location.href = '/login';
    throw error;
  }
}

userRequest.interceptors.response.use(
  response => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedRequestsQueue.push((newToken) => {
            originalRequest.headers['x-access-token'] = newToken;
            resolve(userRequest(originalRequest));
          });
        });
      }

      isRefreshing = true;
      try {
        const newAccessToken = await refreshAccessToken();
        
        originalRequest.headers['x-access-token'] = newAccessToken;
        const retriedResponse = await userRequest(originalRequest);

        failedRequestsQueue.forEach(cb => cb(newAccessToken));
        failedRequestsQueue = [];
        return retriedResponse;
      } catch (refreshError) {
        failedRequestsQueue.forEach(cb => cb());
        failedRequestsQueue = [];
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

// Create our Currency formatter.
export const formatter = {
  format: (value) => {
    // If value is NaN, undefined, null, or falsy, return ₦0
    if (isNaN(value) || value === undefined || value === null || value === '') {
      return '₦0';
    }

    // Ensure the value is a number before formatting
    return new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
      minimumFractionDigits: 0,
    }).format(Number(value));
  },
};